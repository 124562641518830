export class MiscUtility {

    // Convert a HTMLCollectionBase to Array<Element>
    public static collectiontoArray(collection: HTMLCollectionBase): Array<Element> {
        const arr: any[] = [];

        for (let i = 0; i < collection.length; i++) {
            arr.push(collection[i]);
        }
        return arr;
    }

    // Sort a list of objects by 2 properties
    public static sortBy(o: object[], first: string, second: string): any[] {
        if (first === '' || second === '') {
            return o;
        }
        if (!(o && o.length > 0)) {
            return o;
        }
        let isValid = true;

        o.forEach(i => {
            isValid = isValid && i.hasOwnProperty(first) && i.hasOwnProperty(second)
        });

        if (!isValid) {
            return o;
        }

        const sorted = o.sort( (a: any, b: any) => {
            if (a[first] === b[first]) {
                return a[second] > b[second] ? 1 : a[second] < b[second] ? -1 : 0 ;
            } else if (a[first] > b[first]) {
                return 1;
            } else if (a[first] < b[first]) {
                return -1;
            }
        });

        return sorted;
    }
}
